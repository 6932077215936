<template>
  <div
    class="TasksList"
    :style="showModal || finishModal ? 'overflow: hidden; max-height: 100svh;' : ''"
  >
    <div class="BWPContainer">
      <Navbar class="Navbar" />

      <div class="PLContainer">
        <OUIButtonTertary
          :size="'medium'"
          class="BackButton"
          style="margin-bottom: -30px"
          ButtonText="Назад"
          :SendCallback="goBack"
        >
          <template v-slot:LeftIcon>
            <div class="BackImage" />
          </template>
        </OUIButtonTertary>

        <div v-if="project != null" class="PCInfo">
          <span class="PCITitle TextWrapper HyphensAuto" lang="ru">{{
            project?.name
          }}</span>
          <div class="ql-snow" :key="project?.description">
            <span
              class="ql-editor DescText TextWrapper HyphensAuto"
              v-html="project?.description"
              lang="ru"
            ></span>
          </div>
        </div>

        <div class="PTasks">
          <span class="PTTitle TextWrapper">Задачи проекта</span>

          <OUISearchButtons
            searchPlaceholder="Поиск по проектам"
            :searchInput="searchModel"
            @update:searchInput="updateSearchInput"
            :buttons="filters"
            :hideSearch="true"
          />

          <div v-if="project != null" class="PLCList noselect">
            <div
              v-for="(job, jindx) in filteredCards"
              :key="`Job-${jindx}-${job.id}`"
              class="LCard"
              @click.self="
                () => {
                  job_selected = job;
                  showModal = true;
                }
              "
            >
              <div
                @click="
                  () => {
                    job_selected = job;
                    showModal = true;
                  }
                "
                class="CardTop"
              >
                <div class="CDifficult">
                  <span class="CDText TextWrapper">Сложность</span>
                  <div class="Difficulties">
                    <div
                      v-for="x in getTaskDifficult(job.level)"
                      :key="x"
                      class="DifficultFilled"
                    ></div>
                    <div
                      v-for="x in 3 - getTaskDifficult(job.level)"
                      :key="x"
                      class="DifficultEmpty"
                    ></div>
                  </div>
                </div>
              </div>

              <span
                @click="
                  () => {
                    job_selected = job;
                    showModal = true;
                  }
                "
                class="CardDirections TextWrapper"
                >{{ getTaskDirections(job.sections) }}</span
              >
              <span
                @click="
                  () => {
                    job_selected = job;
                    showModal = true;
                  }
                "
                class="CardTitle TextWrapper HyphensAuto"
                lang="ru"
                >{{ job.name }}</span
              >

              <div class="LCBottom">
                <template v-if="userFollowThisTask(job.id)">
                  <div class="RJCPinTaskFilled" @click="clickTaskHeart(job.id)"></div>
                </template>
                <template v-else>
                  <div class="RJCPinTask" @click="clickTaskHeart(job.id)"></div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="BGCollections">
        <div class="BGLight"></div>
      </div>
    </div>

    <div
      v-if="showModal && job_selected != null"
      @click.self="showModal = false"
      class="TaskSelectWrapper"
    >
      <div class="TSModal">
        <div class="TSMHeader">
          <div class="CDifficult">
            <span class="CDText TextWrapper">Сложность</span>
            <div class="Difficulties">
              <div
                v-for="x in getTaskDifficult(job_selected.level)"
                :key="x"
                class="DifficultFilled"
              ></div>
              <div
                v-for="x in 3 - getTaskDifficult(job_selected.level)"
                :key="x"
                class="DifficultEmpty"
              ></div>
            </div>
          </div>
          <div @click="showModal = false" class="CloseModal"></div>
        </div>

        <span class="TaskTitle TextWrapper HyphensAuto">{{ job_selected.name }}</span>

        <div class="ql-snow" :key="job_selected?.description">
          <span
            class="ql-editor TaskDesc TextWrapper HyphensAuto"
            v-html="job_selected?.description"
          ></span>
        </div>

        <Competence
          class="FixCompetence"
          :header="false"
          :userLevels="
            user.user_cells.length > 0 ? user.user_cells.map((cell) => cell.level_id) : []
          "
          :useWhiteListRequireBlock="true"
          :whiteListRequireBlock="getLevelsReq(job_selected.levels)"
          :useAlternateMethod="true"
          :competenceWhiteList="getSectionsArray(job_selected.sections)"
          :viewMode="true"
          :key="`${job_selected.id}${getSectionsArray(job_selected.sections)}`"
        />

        <div class="Buttons">
          <OUIButtonPrimary
            :size="'medium'"
            class="PrimaryReStyle"
            ButtonText="Закрепить задачу"
            @click="changeTask(job_selected?.id)"
          />
          <OUIButtonTertary
            :size="'medium'"
            ButtonText="Нравится"
            @click="clickTaskHeart(job_selected?.id)"
          >
            <template v-slot:LeftIcon>
              <template v-if="userFollowThisTask(job_selected?.id)">
                <div :class="`HeartIcon HeartIMGFilled`" />
              </template>
              <template v-else>
                <div :class="`HeartIcon HeartIMGEmpty`" />
              </template>
            </template>
          </OUIButtonTertary>
        </div>
      </div>
    </div>

    <div v-if="finishModal" @click.self="finishModal = false" class="TaskSelectWrapper">
      <div class="TSModal">
        <div class="TSMHeader">
          <div class="CDifficult">
            <span class="CDText BiggerHeader TextWrapper"
              >Задача закреплена в профиле!</span
            >
          </div>
          <div @click="finishModal = false" class="CloseModal"></div>
        </div>

        <span class="FinalModalText"
          >Ваша приоритетная задача: {{ job_selected?.name }}. <br /><br />

          При необходимости, ты сможешь поменять приоритетную задачу у себя в профиле.
          <br /><br />

          Чтобы приступить к выполнению, необходимо получить новые компетенции:
          {{ job_selected?.sections.map((x) => x?.name).join(", ") }}. <br /><br />

          Тебе понравится, скорее переходи к следующему этапу!
        </span>

        <div class="Buttons">
          <router-link to="/beta/profile">
            <OUIButtonPrimary
              :size="'medium'"
              class="PrimaryReStyle"
              ButtonText="Перейти в профиль"
            />
          </router-link>
          <OUIButtonTertary
            :size="'medium'"
            @click="finishModal = false"
            ButtonText="Я ещё подумаю"
          />
        </div>
      </div>
    </div>

    <div class="lds-dual-ring" v-show="projectsLoading"></div>
    <div class="lds-dual-ring-wrapper" v-show="projectsLoading"></div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar/NavbarCombined.vue";
import OUISearchButtons from "@/components/OctaUI/Filters/SearchButtons.vue";
import OUIButtonPrimary from "@/components/OctaUI/Buttons/primary.vue";
import OUIButtonTertary from "@/components/OctaUI/Buttons/tertary.vue";
import Competence from "@/components/Profile/CompetenceBlock.vue";

import { mapGetters, mapMutations } from "vuex";
import { getProjectWithTasks } from "@/api/project.js";
import { getNProjectWithTasks } from "@/api/n_project.js";
import { findById } from "@/api/user.js";
import { refresh } from "@/api/auth.js";
import {
  pinTask,
  addFollowersJob,
  removeFollowersJob,
  getLatestFollowersJob,
} from "@/api/job.js";

import "./../../assets/css/Repainting/VQuillTheme.css";
import "@vueup/vue-quill/dist/vue-quill.core.prod.css";
import "@vueup/vue-quill/dist/vue-quill.snow.prod.css";

export default {
  name: "RegistrationTasksList",
  components: {
    Navbar,
    OUISearchButtons,
    OUIButtonPrimary,
    OUIButtonTertary,
    Competence,
  },
  data() {
    return {
      filters: {
        directions: {
          name: "Направление",
          list: [],
          selected: [],
          code: "directions",
        },
      },
      searchModel: "",

      job_selected: null,
      project: null,

      projectsLoading: true,
      showModal: false,

      finishModal: false,
    };
  },
  async created() {
    if (this.$route.query?.type == null || this.$route.query?.id == null) {
      this.$router.push("/content");
      return 0;
    }

    if (
      this.$route.query?.type !== "DefaultProject" &&
      this.$route.query?.type !== "NProject"
    ) {
      this.$router.push("/content");
      return 0;
    }

    if (this.$route.query?.type === "DefaultProject") {
      let projectWithTasksResponse = await getProjectWithTasks(this.$route.query?.id);
      this.project = projectWithTasksResponse.data;
    }

    if (this.$route.query?.type === "NProject") {
      let projectWithTasksResponse = await getNProjectWithTasks(this.$route.query?.id);
      this.project = projectWithTasksResponse.data?.[0];
    }

    if (this.project == null) {
      if (this.$route.query?.debug == "true") {
        return 0;
      }

      this.goBack();
    }

    this.project.Jobs = this.project.Jobs.map((x) => {
      return { ...x, subsListModified: [] };
    });

    let JobsDirections = this.project.Jobs.map((_job) =>
      _job.sections.map((x) => x.name)
    ).flat();
    const uniqueJobsDirections = [...new Set(JobsDirections)];

    this.filters.directions.list = uniqueJobsDirections;

    setTimeout(() => {
      this.projectsLoading = false;
    }, 300);
  },
  computed: {
    ...mapGetters({
      user: "auth/isLoggedIn",
    }),

    filteredCards() {
      if (this.project == null || this.project?.Jobs == null) {
        return [];
      }

      if (this.filters.directions.selected.length === 0) {
        return this.project?.Jobs;
      }

      return this.project?.Jobs.filter((_job) => {
        let directions = _job.sections.map((x) => x.name);

        return this.filters.directions.selected.some((item) => directions.includes(item));
      });
    },
  },
  methods: {
    ...mapMutations("auth", ["SET_USER"]),

    updateSearchInput(newValue) {
      this.searchModel = newValue;
    },

    getTaskDifficult(DiffName) {
      let Difficulties = {
        Легкий: 1,
        Средний: 2,
        Сложный: 3,
      };
      return Difficulties[DiffName];
    },

    getTaskDirections(sections) {
      return sections.map((x) => x.name).join(", ");
    },

    goBack() {
      if (window.history.state.back != null) {
        if (window.history.state.back.includes("/content/projects-list")) {
          let filters = [];
          if (this.$route.query?.DirectionsSelected != null) {
            filters.push(`DirectionsSelected=${this.$route.query?.DirectionsSelected}`);
          }

          if (this.$route.query?.subDirectionsSelected != null) {
            filters.push(
              `subDirectionsSelected=${this.$route.query?.subDirectionsSelected}`
            );
          }

          this.$router.push(`/content/projects-list?${filters.join("&")}`);
        } else {
          this.$router.go(-1);
        }
      } else {
        this.$router.push(`/content/projects-list`);
      }
    },

    userFollowThisTask(TaskID) {
      let Task = this.project.Jobs.find((obj) => {
        return obj.id === TaskID;
      });

      if (Task == null) {
        return 0;
      }

      let FindFollow = Task.subsListModified.find((user) => {
        return String(user.id) === String(this.user.id);
      });

      if (FindFollow == null) {
        return false;
      } else {
        return true;
      }
    },
    async updateTaskFollowers(taskID) {
      this.TasksLoading = true;
      let subs = await this.getTaskFollowers(taskID);
      let Task = this.project.Jobs.find((obj) => {
        return obj.id === taskID;
      });
      Task.subsListModified = subs.data.JobFollowers;

      this.TasksLoading = false;
    },
    async clickTaskHeart(taskID) {
      if (this.userFollowThisTask(taskID)) {
        await this.unfollowTask(taskID);
      } else {
        await this.followTask(taskID);
      }

      await this.updateTaskFollowers(taskID);
    },
    async followTask(taskID) {
      let temp = [];
      temp.push(this.user.id);
      await addFollowersJob(taskID, {
        followers: temp,
      });
      findById(this.user.id).then((res) => this.SET_USER(res.data));
    },
    async unfollowTask(taskID) {
      let temp = [];
      temp.push(this.user.id);
      await removeFollowersJob(taskID, {
        followers: temp,
      });
      findById(this.user.id).then((res) => this.SET_USER(res.data));
    },
    async getTaskFollowers(taskID) {
      return await getLatestFollowersJob(taskID);
    },

    getSectionsArray(arr) {
      return arr.map((x) => x.id);
    },
    getLevelsReq(lvls) {
      return lvls.map((x) => x.id);
    },

    async changeTask(taskID) {
      let setTask = await pinTask(taskID, {
        user_id: this.user.id,
      });

      await this.updateUser();

      this.showModal = false;
      this.finishModal = true;
    },

    async updateUser() {
      const response = await refresh();
      const userResponse = response.user;
      this.getUser(userResponse.id);
    },
    getUser(id) {
      if (id != undefined) {
        findById(id).then((response) => {
          this.SET_USER(response.data);
        });
      }
    },
  },
};
</script>

<style scoped>
.FixCompetence {
  overflow-y: visible !important;
}

.BackImage {
  position: relative;
  display: block;

  width: 16px;
  height: 16px;

  background: url("./../../assets/img/ReDesign/interface_icons/arrow_up.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px 16px;

  rotate: -90deg;
}

.BackButton:not(.Disabled):active > .BackImage {
  filter: brightness(0) saturate(100%) invert(42%) sepia(50%) saturate(4871%)
    hue-rotate(224deg) brightness(103%) contrast(101%);
}

.BackButton.Disabled > .BackImage {
  filter: brightness(0) saturate(100%) invert(26%) sepia(53%) saturate(560%)
    hue-rotate(204deg) brightness(93%) contrast(84%);
}

.lds-dual-ring {
  position: absolute;
  z-index: 55555;
}
.lds-dual-ring-wrapper {
  position: absolute;
  z-index: 55554;

  width: 100%;
  height: 100%;

  backdrop-filter: blur(10px);
}

.DifficultEmpty {
  position: relative;
  display: block;

  width: 16px;
  height: 16px;

  background: url("./../../assets/img/profile/ProfileProjectCard/DifficultIconPlaceholder.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  filter: brightness(0) saturate(100%) invert(24%) sepia(8%) saturate(3135%)
    hue-rotate(199deg) brightness(89%) contrast(86%);
}
.DifficultFilled {
  position: relative;
  display: block;

  width: 16px;
  height: 16px;

  background: url("./../../assets/img/profile/ProfileProjectCard/DifficultIcon.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.HeartIcon {
  position: relative;
  display: block;

  width: 24px;
  height: 24px;
}
.HeartIMGEmpty {
  background: url("./../../assets/img/ReDesign/interface_icons/heart_icon_white_16px.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px;
}
.HeartIMGFilled {
  background: url("./../../assets/img/ReDesign/interface_icons/heart_icon_white_filled_16px.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px;
}

.Tertary:not(.Disabled):active > .HeartIcon {
  filter: brightness(0) saturate(100%) invert(35%) sepia(99%) saturate(462%)
    hue-rotate(201deg) brightness(110%) contrast(112%);
}

.RJCPinTask {
  position: relative;
  display: flex;

  margin-top: auto;

  cursor: pointer;

  width: 52px;
  height: 52px;

  background: url("./../../assets/img/ReDesign/interface_icons/heart_icon_white_16px.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 24px;

  border-radius: 100%;
  background-color: #656bff40;
}
.RJCPinTaskFilled {
  position: relative;
  display: flex;

  margin-top: auto;

  cursor: pointer;

  width: 52px;
  height: 52px;

  background: url("./../../assets/img/ReDesign/interface_icons/heart_icon_white_filled_16px.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 24px;

  border-radius: 100%;
  background-color: #564db5;
}

.BGLight {
  position: absolute;
  display: block;

  width: 100%;
  height: auto;

  aspect-ratio: 1 / 0.344;

  background: url("./../../assets/img/ReDesign/pages/registration_pages/tasks_list_bg.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.BGCollections {
  position: absolute;
  display: block;
  inset: 0;

  width: 100%;
  height: 100%;

  z-index: 1;
}
.TextWrapper {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;
}

.TasksList {
  position: relative;

  display: flex;

  width: 100%;
  height: auto;
  min-height: 100svh;

  background-color: rgba(35, 38, 89, 1);
}
.TasksList > .BWPContainer {
  position: relative;

  margin: 0% auto;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 40px;

  padding: 50px 120px 32px 120px;

  width: 100%;
  max-width: 1920px;
  height: auto;
  min-height: 90svh;
}
.BWPContainer > *:not(.BGCollections) {
  z-index: 2;
}
.BGCollections {
  position: absolute;
  display: block;
  inset: 0;

  width: 100%;
  height: 100%;

  z-index: 1;
}
.BWPContainer > .Navbar {
  z-index: 555;
}

.PLContainer {
  position: relative;
  display: flex;

  width: 100%;
  height: fit-content;

  flex-direction: column;
  justify-content: flex-start;
  gap: 80px;
}
.PCInfo {
  position: relative;
  display: flex;

  padding: 32px;

  width: 100%;
  height: fit-content;

  flex-direction: column;
  justify-content: flex-start;
  gap: 32px;

  background-color: rgba(41, 45, 104, 0.8);

  border-radius: var(--o-s-global-border-radius);
}

.PCITitle {
  font-family: "Montserrat";
  font-size: 36px;
  font-weight: 700;
  line-height: 44px;
  text-align: left;

  color: rgba(255, 255, 255, 0.9);
}
.PTasks {
  position: relative;
  display: flex;

  width: 100%;
  height: fit-content;

  flex-direction: column;
  justify-content: flex-start;
  gap: 32px;
}

.PTTitle {
  font-family: "Montserrat";
  font-size: 36px;
  font-weight: 700;
  line-height: 44px;
  text-align: left;

  color: rgba(255, 255, 255, 0.9);
}

.PLCList {
  position: relative;
  display: grid;

  width: 100%;
  height: fit-content;

  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
}
.LCard {
  position: relative;
  display: flex;

  padding: 20px;

  min-width: 0;
  width: 100%;
  min-height: 328px;
  height: auto;

  flex-direction: column;
  justify-content: flex-start;
  gap: 12px;

  border-radius: var(--o-s-global-border-radius);

  background-color: rgba(41, 45, 104, 0.8);

  transition: 0.2s;

  cursor: pointer;
}
.LCard:hover {
  background-color: rgba(48, 53, 126, 0.8);
}
.CardDirections {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.25px;
  text-align: left;

  color: rgba(255, 255, 255, 0.6);
}
.CardTitle {
  margin-bottom: 12px;

  font-family: "Montserrat";
  font-size: 22px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;

  color: white;
}
.LCBottom {
  position: relative;
  display: flex;

  margin-top: auto;

  width: 100%;
  height: fit-content;

  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;
  flex-wrap: wrap;
}

.CardTop {
  position: relative;
  display: flex;

  margin-bottom: 12px;

  width: 100%;
  height: fit-content;

  flex-direction: row;
  justify-content: flex-start;
  gap: 24px;
  flex-wrap: wrap;
}
.CDifficult {
  position: relative;
  display: flex;

  width: fit-content;
  height: fit-content;

  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;
}
.CDText {
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: left;

  color: rgba(255, 255, 255, 0.6);
}
.Difficulties {
  position: relative;
  display: flex;

  width: fit-content;
  height: fit-content;

  flex-direction: row;
  justify-content: flex-start;
}
.ql-editor.DescText {
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;

  width: 100%;

  padding: 0;

  color: rgba(255, 255, 255, 0.6);
}

.TaskSelectWrapper {
  position: fixed;
  display: flex;

  inset: 0;

  width: 100%;
  height: 100svh;

  z-index: 700;

  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(10px);
}
.TSModal {
  position: relative;
  display: flex;

  padding: 32px;
  margin: auto;

  width: fit-content;
  max-width: 95%;

  height: fit-content;
  max-height: 90svh;

  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;

  background-color: #232659;
  border-radius: var(--o-s-global-border-radius);

  overflow-x: hidden;
  overflow-y: auto;
}

.TSMHeader {
  position: relative;
  display: flex;

  min-width: 0;
  width: 950px;
  max-width: 100%;

  height: fit-content;

  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
}
.CloseModal {
  position: relative;
  display: block;

  cursor: pointer;

  width: 24px;
  height: 24px;

  background: url("./../../assets/img/ReDesign/interface_icons/close_icon_24px.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 24px;

  filter: brightness(0) saturate(100%) invert(35%) sepia(10%) saturate(1697%)
    hue-rotate(199deg) brightness(94%) contrast(83%);
}
.TextWrapper.TaskTitle {
  min-width: 0;
  width: 950px;
  max-width: 100%;

  height: fit-content;

  font-family: "Montserrat";
  font-size: 28px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;

  color: #ffffffe5;
}
.TextWrapper.TaskDesc {
  padding: 0;

  min-width: 0;
  width: 950px;
  max-width: 100%;

  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.1px;
  text-align: left;

  color: #ffffff99;
}
.Buttons {
  position: relative;
  display: flex;

  min-width: 0;
  width: 950px;
  max-width: 100%;

  height: fit-content;

  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;
  flex-wrap: wrap;
}

.PrimaryReStyle {
  height: auto;
}

.FinalModalText {
  position: relative;
  display: block;

  min-width: 0;
  max-width: 100%;
  width: 950px;

  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  color: #fff;
}
.CDText.BiggerHeader {
  font-size: 20px;
  color: white;
}
</style>

<style scoped>
@media (max-width: 480px) {
  .TasksList > .BWPContainer {
    gap: 30px;
    padding: 20px 16px 32px 16px;
  }

  .PLCList {
    grid-template-columns: repeat(1, 1fr);
  }

  .HyphensAuto {
    hyphens: auto;
    overflow-y: scroll;
  }

  .PCITitle,
  .PTTitle {
    font-size: 18px;
    line-height: 120%;
  }

  .ql-editor.DescText {
    font-size: 14px;
    line-height: 140%;
  }

  .PCInfo {
    padding: 24px;
    gap: 16px;
  }

  .TextWrapper.TaskTitle {
    font-size: 20px;
    line-height: 120%;
  }

  .TextWrapper.TaskDesc {
    font-size: 12px;
    line-height: 120%;
  }

  .TSModal {
    padding: 16px;
  }
  .CDText.BiggerHeader {
    font-size: 14px;
  }
  .FinalModalText {
    font-size: 13px;
  }
}
@media (min-width: 480px) and (max-width: 768px) {
  .TasksList > .BWPContainer {
    gap: 30px;
    padding: 20px 16px 32px 16px;
  }

  .PLCList {
    grid-template-columns: repeat(1, 1fr);
  }

  .HyphensAuto {
    hyphens: auto;
    overflow-y: scroll;
  }

  .PCITitle,
  .PTTitle {
    font-size: 22px;
    line-height: 120%;
  }

  .ql-editor.DescText {
    font-size: 15px;
    line-height: 140%;
  }

  .PCInfo {
    padding: 24px;
    gap: 20px;
  }

  .TextWrapper.TaskTitle {
    font-size: 20px;
    line-height: 120%;
  }

  .TextWrapper.TaskDesc {
    font-size: 12px;
    line-height: 120%;
  }

  .TSModal {
    padding: 16px;
  }
  .CDText.BiggerHeader {
    font-size: 14px;
  }
  .FinalModalText {
    font-size: 13px;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .TasksList > .BWPContainer {
    gap: 30px;
    padding: 20px 60px 32px 60px;
  }

  .PLCList {
    grid-template-columns: repeat(1, 1fr);
  }

  .PCITitle,
  .PTTitle {
    font-size: 26px;
    line-height: 120%;
  }

  .ql-editor.DescText {
    font-size: 18px;
    line-height: 140%;
  }

  .PCInfo {
    padding: 24px;
    gap: 24px;
  }
}
@media (min-width: 992px) and (max-width: 1400px) {
  .TasksList > .BWPContainer {
    gap: 30px;
    padding: 20px 60px 32px 60px;
  }

  .PLCList {
    grid-template-columns: repeat(2, 1fr);
  }

  .PCITitle,
  .PTTitle {
    font-size: 30px;
    line-height: 120%;
  }

  .ql-editor.DescText {
    font-size: 19px;
    line-height: 140%;
  }

  .PCInfo {
    padding: 28px;
    gap: 24px;
  }
}

/* @media (max-width: 992px) {
  .BGLight {
    position: fixed;
    height: 100%;
    background-position: center;
    background-size: 180%;
  }
} */
</style>
